import React,{ useState }  from 'react'
import {useHistory}        from 'react-router-dom'
import ThemeIcon           from './auxiliary/ThemeIcon'
import {AC_DEFAULT_THEME, AC_DARK_THEME} from '../store'

export default function Sidebar(props) {
    const [isVisible,setVisibility] = useState(false);

    const history =  useHistory();

    if(props.lang === undefined) return null;
    return (
        <div className={`Sidebar ${isVisible ? 'uncollapsed':'collapsed'}`}>
            <div className="icon-settings">
                <svg onClick={() => setVisibility(!isVisible)}
                     aria-hidden="true" focusable="false" data-prefix="fas" data-icon="cog" className="menu-icon" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M487.4 315.7l-42.6-24.6c4.3-23.2 4.3-47 0-70.2l42.6-24.6c4.9-2.8 7.1-8.6 5.5-14-11.1-35.6-30-67.8-54.7-94.6-3.8-4.1-10-5.1-14.8-2.3L380.8 110c-17.9-15.4-38.5-27.3-60.8-35.1V25.8c0-5.6-3.9-10.5-9.4-11.7-36.7-8.2-74.3-7.8-109.2 0-5.5 1.2-9.4 6.1-9.4 11.7V75c-22.2 7.9-42.8 19.8-60.8 35.1L88.7 85.5c-4.9-2.8-11-1.9-14.8 2.3-24.7 26.7-43.6 58.9-54.7 94.6-1.7 5.4.6 11.2 5.5 14L67.3 221c-4.3 23.2-4.3 47 0 70.2l-42.6 24.6c-4.9 2.8-7.1 8.6-5.5 14 11.1 35.6 30 67.8 54.7 94.6 3.8 4.1 10 5.1 14.8 2.3l42.6-24.6c17.9 15.4 38.5 27.3 60.8 35.1v49.2c0 5.6 3.9 10.5 9.4 11.7 36.7 8.2 74.3 7.8 109.2 0 5.5-1.2 9.4-6.1 9.4-11.7v-49.2c22.2-7.9 42.8-19.8 60.8-35.1l42.6 24.6c4.9 2.8 11 1.9 14.8-2.3 24.7-26.7 43.6-58.9 54.7-94.6 1.5-5.5-.7-11.3-5.6-14.1zM256 336c-44.1 0-80-35.9-80-80s35.9-80 80-80 80 35.9 80 80-35.9 80-80 80z"></path></svg>
            </div>
            {isVisible && <div className="settings-wrapper">
                <div className="settings-title">{props.lang.txtChangeLanguage}</div>
                <div className="settings-2column">
                        <span className="settings-text"><img alt="Flag US" src="./logos/flags/us.png" className="settings-flag" /></span><span><button className="sb-link" onClick={props.applyLanguage.bind(this,'en')}>{props.lang.langEN}</button></span> 
                        <span className="settings-text"><img alt="Flag FR" src="./logos/flags/fr.png" className="settings-flag" /></span><span><button className="sb-link" onClick={props.applyLanguage.bind(this,'fr')}>{props.lang.langFR}</button></span>
                        <span className="settings-text"><img alt="Flag DE" src="./logos/flags/de.png" className="settings-flag" /></span><span><button className="sb-link" onClick={props.applyLanguage.bind(this,'de')}>{props.lang.langDE}</button></span>
                        <span className="settings-text"><img alt="Flag RO" src="./logos/flags/ro.png" className="settings-flag" /></span><span><button className="sb-link" onClick={props.applyLanguage.bind(this,'ro')}>{props.lang.langRO}</button></span>
                </div>

                
                <div className="settings-title">{props.lang.txtChangeTheme}</div>
                <div className="settings-2column">
                        <span className="settings-text"><ThemeIcon /></span><span><button className="sb-link" onClick={()=>{props.applyTheme(AC_DEFAULT_THEME)}}>{props.lang.themeDefault}</button></span> 
                        <span className="settings-text"><ThemeIcon /></span><span><button className="sb-link" onClick={()=>{props.applyTheme(AC_DARK_THEME)}}>{props.lang.themeDark}   </button></span>
                </div>

                <div className="settings-title">{props.lang.txtBuyACoffee}</div>
                <div className="settings-1column">
                        <span className="settings-text sb-link"><button className="sb-link" onClick={ () => {  history.push('/donate') }}>{props.lang.txtDonate}</button></span> 
                </div>                
            </div>
            }
        </div>
    )
}
