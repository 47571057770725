import React,{useEffect} from 'react'
import {GApageView} from '../../GA'; 

export default function Index(props) {
    
    useEffect(() => { GApageView("Site Index"); }, []);
    if(props.lang === undefined) return null;

    return (
        <>
            <div className="index">
                {props.lang.intro}<br/>
                {props.lang.txtWelcome}
                {props.lang.txtBrowseAround}<br/>
            </div>
        </>
    )
}
