import React,{useEffect} from 'react'
import {GApageView} from '../../GA'; 
import SocialLink from '../auxiliary/SocialLink'

export default function Social(props) {
    useEffect(() => { GApageView("Social"); }, []);
    return (
        <>
            <div className="social">
                     <SocialLink network="twitter" link="https://twitter.com/stomydent" />
                     <SocialLink network="github"  link="https://github.com/stomydent" />
            </div>
        </>
    )
}
