import React from 'react'
import { Link } from 'react-router-dom';

export default function NavMenu(props) {
    
    if(props.lang === undefined) return null;
    return (
        <nav className="Navigation" >
                <Link to="/about">  {props.lang.menu.about}  </Link>
                <Link to="/social"> {props.lang.menu.social} </Link>
                <Link to="/skills"> {props.lang.menu.skills} </Link>
                <Link to="/pgp" >   {props.lang.menu.pgp}    </Link>
                <Link to="/contact">{props.lang.menu.contact}</Link>
        </nav>
    )
}
