import React,{useEffect}    from 'react'
import {GApageView} from '../../GA'; 
import Skill    from '../auxiliary/Skill'
import Skillset from '../auxiliary/Skillset'

export default function Skills(props) {
    useEffect(() => { GApageView("Skills"); }, []);
    if(props.lang === undefined) return null;
    return (
            <div className="skills">
                <Skillset title={props.lang.txtWebservers}>
                    <Skill name="Apache"         logo="./logos/skills/apache.svg" />
                    <Skill name="Node"           logo="./logos/skills/nodejs.svg" />
                </Skillset>

                <Skillset title={props.lang.txtPackageManagers}>
                    <Skill name="NPM"            logo="./logos/skills/npm.svg" />
                    <Skill name="Git"            logo="./logos/skills/git-icon.svg" />
                </Skillset>

                <Skillset title={props.lang.txtLanguages}>
                    <Skill name="CSS 3"          logo="./logos/skills/css-3.svg" />
                    <Skill name="HTML 5"         logo="./logos/skills/html-5.svg" />
                    <Skill name="JavaScript"     logo="./logos/skills/javascript.svg" />
                    <Skill name="PHP"            logo="./logos/skills/php.svg" />
                    <Skill name="C#"             logo="./logos/skills/c-sharp.svg" />
                    <Skill name="ASM"            logo="./logos/skills/ASM.svg" />
                </Skillset>
                
                <Skillset title={props.lang.txtFrameworks}>
                    <Skill name="Express"        logo="./logos/skills/express.svg" />
                    <Skill name="React"          logo="./logos/skills/react.svg" />
                    <Skill name="Redux"          logo="./logos/skills/redux.svg" />
                    <Skill name="Electron"       logo="./logos/skills/electron.svg" />
                    <Skill name="Elixir Phoenix" logo="./logos/skills/phoenix.svg" />
                    <Skill name="Wordpress"      logo="./logos/skills/wordpress-icon.svg" />
                </Skillset>
                
                <Skillset title={props.lang.txtLibraries}>
                    <Skill name="JQuery"         logo="./logos/skills/jquery.svg" />
                    <Skill name="Passport"       logo="./logos/skills/passport.svg" />
                    <Skill name="SocketIO"       logo="./logos/skills/socket.io.svg" />
                    <Skill name="Sass"           logo="./logos/skills/sass.svg" />
                </Skillset>
                
                <Skillset title={props.lang.txtDatabases}>
                    <Skill name="MySQL"          logo="./logos/skills/mysql.svg" />
                    <Skill name="Maria"          logo="./logos/skills/mariadb-icon.svg" />
                    <Skill name="Mongo"          logo="./logos/skills/mongodb.svg" />
                    <Skill name="Redis"          logo="./logos/skills/redis.svg" />
                </Skillset>
                
                <Skillset title={props.lang.txtMiscellaneous}>
                    <Skill name="Webpack"        logo="./logos/skills/webpack.svg" />
                    <Skill name="SecOps"         logo="./logos/skills/secops.svg" />
                    <Skill name="Stripe"         logo="./logos/skills/stripe.svg" />
                </Skillset>

                <div>
                    <br/>
                    <br/>
                </div>
            </div>
    )
}
