import React from 'react'

export default function Skill(props) {
    if(props === undefined) return null;

    return (<div className="skill">
                <div className="skill-icon">
                    <img alt="Skill icon" src={props.logo} />
                </div>
                <div className="skill-description">{props.name}</div>
            </div>)
}
