export const isset = (any) =>
{ 
    if (any === null) return false;
    if (any === undefined) return false;
    switch(typeof any)
    {
        case "boolean":
            if(any === false)return false;
            if(any === true )return true;
        break;

        case "string":
            if( any===undefined )return false;
            if( any===null      )return false;
            if( any.length===0   )return false;
            if( any===""        )return false;
            if( any.trim()==="" )return false;
                if( any!==undefined )return true;
                if( any!==null      )return true;
        break;

        case "object":
            if(Object.keys(any).length === 0){
                return false;
            }else{
                return true;
            }

        default:
    }
}

// Cookie functions
export class Cookie{
    getCookieObject(){
        let pre_cookie_array = document.cookie;
        let cookie_array     = {};
    
        if(pre_cookie_array.indexOf(";") !== -1) 
        {
            pre_cookie_array = pre_cookie_array.split(";");
        }else{
            let tmp = [];
            tmp[0] = pre_cookie_array;
            pre_cookie_array = [];
            pre_cookie_array[0] = tmp[0];
        }
    
        for(let cookie of pre_cookie_array)	{
            let aux = cookie.trim().split("=");cookie_array[aux[0]] = aux[1];
        }
        return cookie_array;
    }
    setCookie(Name,Value){document.cookie = `${Name}=${Value}; expires=Fri, 31 Dec 9999 23:59:59 GMT`;}
    getCookie(Name){let aux = this.getCookieObject();return aux[Name];}
    removeCookie(Name){document.cookie = `${Name}=; Max-Age=-99999999;`;}
}

export default Cookie;