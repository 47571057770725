import React from 'react'

export default function Skills(props) {
    if(props === undefined) return null;

    return (
                <div className="skillset">
                    <div className="skillset-title">{props.title}</div>
                    <div className="skillset-icons">
                        {props.children}
                    </div>
                </div>
    )
}
