import React,{ useState, useEffect }  from 'react'
import Cookie,{isset}                 from './helpers/meta'

import Avatar  from './components/Avatar'
import NavMenu from './components/NavMenu'
import Sidebar from './components/Sidebar'
import Main    from './components/Main'

import { AC_DEFAULT_THEME, AC_DEFAULT_LOCALE } from './store'

import {initGA} from './GA'; 

function App() 
{
    const CookieJar = new Cookie()
    var theme       = CookieJar.getCookie('theme')
    var locale      = CookieJar.getCookie('locale')

    if(!isset(theme)) {  CookieJar.setCookie('theme', AC_DEFAULT_THEME);   theme  = AC_DEFAULT_THEME;    }
    if(!isset(locale)){  CookieJar.setCookie('locale', AC_DEFAULT_LOCALE); locale = AC_DEFAULT_LOCALE;   }

    // eslint-disable-next-line
    const [_Theme, setTheme] = useState(theme);
    const [_Locale, setLocale] = useState(locale);
    const [_Translation, setLanguage] = useState({});

    import(`./scss/App-${_Theme}.scss`).then( ar => {})
    import(`./scss/Cookies.scss`).then( ar => {})
    
    useEffect(()=>{
      async function fetchTranslation(){
        return await (await fetch(`./lang/i18n-${_Locale}.json`)).json();
      }
      fetchTranslation().then(ar => setLanguage(ar));
    }, [_Locale])

    useEffect(() => {initGA()}, []);

    const applyLanguage = async (LangCode) => { 
      CookieJar.setCookie('locale',LangCode)
      setLanguage(await (await fetch(`./lang/i18n-${LangCode}.json`)).json());
      setLocale(LangCode);     
    }
    const applyTheme = async (Theme) => 
    { 
        CookieJar.setCookie('theme',Theme);

        await fetch(`./${process.env.REACT_APP_CSS_PATH}/App-${Theme}.css`).then(resp => {return resp.text()})
        .then(resp =>{
          let NewCSS = document.createElement('style');
              NewCSS.type='text/css';
              NewCSS.innerText=resp;
          document.head.appendChild(NewCSS);
        })
    }


  return (
    <div className="App">
        <header className="Header">
          <Avatar />
          <NavMenu lang={_Translation.navmenu} />
          <Sidebar lang={_Translation.sidebar} applyLanguage={(LangCode) => applyLanguage(LangCode)} applyTheme={(Theme) => applyTheme(Theme)} />
        </header>
          <Main    lang={_Translation.pages} />

          <footer className="cookies">
                <span className="cookies-message">I use cookies kk ?</span>
          </footer>
    </div>
  );
}

export default App;
