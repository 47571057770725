import React,{useEffect} from 'react'
import {GApageView} from '../../GA'; 

export default function Contact(props) {
    useEffect(() => { GApageView("Contact"); }, []);
    if(props.lang === undefined) return null;

    const copyClipboard = async () => {
        if (!navigator.clipboard) {
            console.log(`[AC] You have a nasty old browser`)
            return false;
          }
        await navigator.clipboard.writeText(atob(props.lang.addrEmail));
        prompt('', `Copied ${atob(props.lang.addrEmail)} clipboard`)
    }

    return (
        <>
            <div className="contact">
                 <div className="contact-txt">{props.lang.txtEmail}</div>
                 <div className="contact-email"><a href={`mailto:${atob(props.lang.addrEmail)}`}>{atob(props.lang.addrEmail)}</a></div>
                 <div>
                 <br/>
                    <button className="btnEmail" onClick={()=>{ copyClipboard() }}><i className="clipboard"></i>&nbsp;{props.lang.txtCopyAddress}</button>
                 </div>
            </div>
        </>
    )
}
