import React from 'react'
import { useHistory } from "react-router-dom";

export default function Avatar()
{
    let history = useHistory()


    return (
        <section className="Avatar">
          <img alt="Avatar" src="/images/avatar.png" className="avatar" onClick={()=>history.push('/')} />
        </section>
    )
}