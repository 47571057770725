import React,{useEffect} from 'react'
import {GApageView} from '../../GA'; 

export default function Donate(props) {
    useEffect(() => { GApageView("Donate"); }, []);
    if(props.lang === undefined) return null;

    const btc = React.createRef();
    const eth = React.createRef();
    const copyBTC = async () => {
        if (!navigator.clipboard) {
            console.log(`[AC] You have a nasty old browser`)
            return false;
          }
        await navigator.clipboard.writeText(btc.current.value);
    }
    const copyETH = async () => {
        if (!navigator.clipboard) {
            console.log(`[AC] You have a nasty old browser`)
            return false;
          }
        await navigator.clipboard.writeText(eth.current.value);
    }

    return (
        <div className="donate">
            <div className="don-text">{props.lang.txtBegging}</div>
            <br/>
            <div className="don-wallets">
                <span className="don-wallet">
                     <div>
                         <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="btc" className="crypto-icon" color="orangered" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M310.204 242.638c27.73-14.18 45.377-39.39 41.28-81.3-5.358-57.351-52.458-76.573-114.85-81.929V0h-48.528v77.203c-12.605 0-25.525.315-38.444.63V0h-48.528v79.409c-17.842.539-38.622.276-97.37 0v51.678c38.314-.678 58.417-3.14 63.023 21.427v217.429c-2.925 19.492-18.524 16.685-53.255 16.071L3.765 443.68c88.481 0 97.37.315 97.37.315V512h48.528v-67.06c13.234.315 26.154.315 38.444.315V512h48.528v-68.005c81.299-4.412 135.647-24.894 142.895-101.467 5.671-61.446-23.32-88.862-69.326-99.89zM150.608 134.553c27.415 0 113.126-8.507 113.126 48.528 0 54.515-85.71 48.212-113.126 48.212v-96.74zm0 251.776V279.821c32.772 0 133.127-9.138 133.127 53.255-.001 60.186-100.355 53.253-133.127 53.253z"></path></svg>
                         <span className="crypto-name">Bitcoin (BTC)</span>
                    </div>
                     <div><input ref={btc} type="text" className="don-crypto-address" defaultValue="1GNFSitByVKf5DwpkgJRfbYeNXisk6xDLo" /> <button className="btnClipboard" onClick={()=>{copyBTC()}}><i className="clipboard"></i>{props.lang.txtClipboard}</button></div>               
                </span>
                <span className="don-wallet">
                     <div>
                         <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="ethereum" className="crypto-icon" color="dimgray" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M311.9 260.8L160 353.6 8 260.8 160 0l151.9 260.8zM160 383.4L8 290.6 160 512l152-221.4-152 92.8z"></path></svg>
                         <span className="crypto-name">Ethereum (ETH)</span>
                    </div>
                     <div><input ref={eth} type="text" className="don-crypto-address" defaultValue="0xa9B8D5079638036ACEa6eEdd118f48AB12A0F1F3" /> <button className="btnClipboard" onClick={()=>{copyETH()}} ><i className="clipboard"></i>{props.lang.txtClipboard}</button></div>
                </span>
            </div>
        </div>
    )
}
