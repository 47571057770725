import React from 'react'
import { Route, Switch } from 'react-router-dom';

import Index   from   './pages/Index'
import About   from   './pages/About'
import Social  from   './pages/Social'
import Skills  from   './pages/Skills'
import Contact from   './pages/Contact'
import PGP     from   './pages/PGP'
import Donate  from   './pages/Donate'

import Error from './pages/Error'


export default function Main(props) {
    
    if(props.lang === undefined) return null;
    return (
        <main>
            <Switch>
                <Route exact path='/'         component={() => <Index    {...props} lang={props.lang.index}   />} />
                <Route exact path='/about'    component={() => <About    {...props} lang={props.lang.about}   />} />
                <Route exact path='/social'   component={() => <Social   {...props} />} />
                <Route exact path='/skills'   component={() => <Skills   {...props} lang={props.lang.skills}  />} />
                <Route exact path='/contact'  component={() => <Contact  {...props} lang={props.lang.contact} />} />
                <Route exact path='/pgp'      component={() => <PGP      {...props} lang={props.lang.pgp} />     } />
                {/* <Route exact path='/blog'     component={() => {window.location.href = 'http://calin.swfdigital.com/blog';return null;}} /> */}
                <Route exact path='/donate'   component={() => <Donate   {...props} lang={props.lang.donate}  />} />
                <Route                        component={() => <Error    {...props} lang={props.lang.error}   />} />
            </Switch>
        </main>
    )
}
