import React,{useEffect} from 'react'
import {GApageView} from '../../GA'; 
export default function About(props) {
    
    useEffect(() => { GApageView("About"); }, []);
    if(props.lang === undefined) return null;

    return (
        <>
            <div className="about">
                <div className="about-paragraph">
                    {props.lang.txtAboutLocation}<br/><br/>
                    {props.lang.txtAboutWebdev}<br/><br/>
                    {props.lang.txtAboutProjects}<br/><br/>
                    {props.lang.txtAboutLanguages}<br/><br/>
                    {props.lang.txtOtherHobbies}
                </div>
            </div>
        </>
    )
}
