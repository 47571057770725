import React,{useEffect} from 'react';
import {GApageView} from '../../GA'; 

export default function PGP(props) {
    useEffect(() => { GApageView("PGP") }, []);
    if(props.lang === undefined) return null;


    return (
<pre className='pgp'>
{`-----BEGIN PGP PUBLIC KEY BLOCK-----

mQINBGS7kjUBEADXI4nAVBfzodDc//pW46G0rb2inq4BaEkCAISAfCsd7v+g06g9
YJjMSGHqbwAYFTzkvAZJ5ppL3bxqE3ohLO9rI4MCVjQiKC27/17IofgC93Wcvaz4
036jcqztFK9Qqd+dMGMM4WFMQzzjTxSS9TbQlDMRru2bvpMy3eXTXT2qfeDCUsPJ
A5OAxT7tQI96G76zfD3vDa6lFXgvPH0jFc5tcfpaw6yjGoUGNN+mZOTdeFQXwl3o
8eBhWHM0T5LLSCdFusSrzHH6dHse/enQVHbWdZQSgmx9tn/zfQQI/rlQSYQIGxI/
JekAHAmwZx4ZlmUwWlCWhmjkCx7Bt/sSEzAn61kD33sE3w9/sQLiU9IRUvun27Yg
EVkWq5UWRJRiUhd/sN68kbC9n+4x+/B97TKA26H4GuRxUfaYIH15ZtRO2SH00f8M
ecWE87nfGw5h8aduv1zl/TTcUui/rtwfiz1exUdCYcCM/e92ss6Ekgc5LnKs8+f0
cQGz7oZNn54mOKcsADgtFrw3N72HuujNEZa49BYl7IEyaepUJTeQGTuNt1cFg8mi
p4QMpIS0JUARCGLQAEkWl/oON8TLsge4tkZ9RGCWjMWMCh3EZ+CgLmllhiLttY5N
OC8TTtrb5R9/oD3Zl5Ia2RGnt9sOTLURRziifRWbttK6P6N7bxN/xOTBAQARAQAB
tB9BLiBDYWxpbiAoR2VuZXJpYykgPGVjeEBnbXguZnI+iQJUBBMBCAA+FiEELNQD
fdjoXGDMEntxOFzYRGnss5oFAmS7kjUCGwMFCQHhM4AFCwkIBwIGFQoJCAsCBBYC
AwECHgECF4AACgkQOFzYRGnss5oEdQ/9FN4Ro7PJI9gHTFQuMNyuogIzZ+H+Orjl
vpviV+NwKRTQXQPeXofE2EewHDPpepTTtOqAnfF6DV+a7GZ8rXUjohGrilTe7jay
YKQtAFEkERMcXbopXbjBRS86gXCzDoCHLZVF2xonCXTV9hNP8FrabdSCPWJZ1KlK
Pur0ByQiLe8LMjnfzvu4OhzMkFFa9a8XEn+tbegqzS+3aOjK3c0ZAETUjw/V86Iw
U95buu4X+lZJ+6AT57nIYtiyr2Fp86N1sCo7Sm+tn1VwCWtU9FciF7yaBZYjpmIO
Lw04i0tNWwBaHBndab+4600Q7xlL0yjdUnoVJO1aYiU0ZHSxHNW8TVPWLjtctmYZ
6NIhBH3/6RuQvs6sOgYLt1ZDJkV38vsPWUZKWKwCk5WLNMUjqDDhoxAY8H58mYgW
F0Px2OhQhxNx8YDhto+7C+5aJwfK2TeuhCb5IhPphbRz6MnjsLa47rQWl4rDWaqs
geDqQXGD1cw54Z7O95WJFfb2VZEuBCpC8Zafyrtxm87/Qbbm1jUjF6ekF4gSPr3H
AFD/Mwx+vy8DVIzMwLfYeJyfAwztKhQcvBRBbodcHz8BVCgLYNWq+8jqeVeBvG3T
9hfLwrBkZXOJWK2SJQ7MhZ5bK5ekvB2lMiUOLO3O1zcczqA1bh3SQ4SRU9Ni3XUU
j5hFOJ8rCwO5Ag0EZLuSNQEQAL4eGMdhphPets0gmASSEIQCU8KEpmaNpvUARVLo
7bGmFcKkw2EAcJoCqgkt0kHeteQF+kTTn/34mdlrYBvB5hNgV7Iedr3ajUE4aTjJ
tIExqclUOX+HNwEMp82eykojLHexR5cWLJ2mJf7ez0EVbcW9jvqHrVyv8rRnqlji
mh21b0RKVNvYvyqmKnRnxYhTGjuDfBGhRXbjC0cSxxLqwiULoRxxJ+0yNNtkrnxq
sVW385THn4IGMML+PKtS5SWg8+nY7qNAHVB4J5R8S+OYtjwS10nwtfOJmTd2FV/z
LCL7k9SZL3BeqHQK2mcRC5cShKv5JoT+3cWigok7h47zXgVwH0+r73EdgMc4okRQ
+xL5MXke6Bd5WWdI81mpe0Jv8he2IokpukVbE8XoGfuexYSotLymj9ol1Eo3Bwlz
Gm2b7kkFWoSORlO19KVDbkuVRs3d8ZX0yAsUT0EkmqJueYRMXcu+cZf2EBu4a0bw
5FDpfakBs3OSy+Wm5kQV7fw1Hq29lolF4C/OD7O7sOfFzfOgUlC4Wsh/EIZ2MZHz
KX4h+/TIAZwNo9Nv+rnl6KnNh4DRPnVf94IpWDNSSKQpvcZZJ4KSRcpdEvC6vkjP
Nh6DI83E/5TmoYwUt5nSpOwBgmnj/nw4ZRfNzg1Dq5RMnD8sjZ5u2P0s1Iin+QC9
hWeTABEBAAGJAjwEGAEIACYWIQQs1AN92OhcYMwSe3E4XNhEaeyzmgUCZLuSNQIb
DAUJAeEzgAAKCRA4XNhEaeyzmhP5D/4pewIzYxr2q53REMZRCj63L6aJ7plzlzxe
do99ehG3RKIJ3yn90hHGNmY3XmKouVAAhOZcPO3UzqjAEA+JsJNJf/jguZVEp3N7
R3gtCOmDfoUy58CiUcdtaVisYbng3RTJtIR2KBxYdBk7rHMMNktBp0Diz94uiWqI
Deyf6xDlXGYbD07AlrZxXRl6ukrOTDStCmsM8tqrk5qLIONMpT7Ul4YeVggnJgls
XBg0GZVrmHGWRPm9fW3ZGuNgxSEuwcH8QAxSHOtGyP8Ys44s0W5pYmXdU1SqZsbM
oph3z1v3S2fXTPZptRwKYwDao24riTyUEZIEX4k3H+LGSfKMnLA6GRTmXMRWgnHY
w60e+jaTqcmVKtiogn87YPkLuEhClT3vvdiEYKUPGuC49jx9IsUfNUwWox8aLwc6
4hq1566c1gdY1H/qJqWZ4Za7CQFbUfUaEvFgDYTlxOjiBD10HMdqORH3Yay4oRHF
P1mUPB8NECsoU7Fqye88QEFvORDHfUGTjnogp2nqvA/iCZAu5GJI1yBw+N7PazBw
IrLerMjOKN5YZk/XNkyXtDYgKXhCvEChiLDi3NYv4hh3yfx0ypieZgnuYeZFjhzK
kFkZb0qj1l9OpVUZDVrPLQj+q2+539FCkJizk11uJECEVUMHuAQPCMhAT1wyZhzS
Xch+CmHLvQ==
=9krR
-----END PGP PUBLIC KEY BLOCK-----`}
</pre>
    )
}
