import React,{useEffect} from 'react'
import {GApageView} from '../../GA'; 

export default function Error(props) {
    useEffect(() => { GApageView(window.location.pathname + window.location.search); }, []);

    if(props.lang === undefined) return null;
    return (
        <div className="error">
            <mark>404 {props.lang.error_description}</mark>
            <br/>
            ⛔
        </div>
    )
}
